import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IActivity, IOrganizationUnitOverview } from 'typings/doenkids/doenkids';
import { IEntityWithAccessLevel } from 'typings/api-generic';
import { IOrganizationUnitActivityAssignments, IRelatedActivityResponse, IActivityListResponse, ICountResponse, IActivityConnectionList, IActivityActivityExportBody } from 'typings/api-activity';
import { IEventLogListResponse } from 'typings/api-customer';
import { firstValueFrom, Observable } from 'rxjs';
import { DoenkidsFileDownloader } from 'src/providers/download-files.provider';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ActivityStore } from './activity.store';
import { ActivityQuery } from './activity.query';
import { I18nToastProvider } from '../../../providers/i18n-toast.provider';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  constructor(
    private store: ActivityStore,
    private query: ActivityQuery,
    private $baseApi: DoenKidsGenericApiProvider,
    private $i18nToastProvider: I18nToastProvider,
    private $doenkidsFileDownloaderService: DoenkidsFileDownloader,
  ) {}

  async fetch(activityId: number) {
    try {
      const fetchedActivity = (await firstValueFrom(this.$baseApi.genericGetCall(`/activity/activity/${activityId}`))) as IEntityWithAccessLevel<IActivity>;

      if (fetchedActivity) {
        this.store.upsert(fetchedActivity.data.id, {
          id: fetchedActivity.data.id,
          data: fetchedActivity.data,
          access_level: fetchedActivity.access_level,
        });
      }

      return fetchedActivity;
    } catch (e) {
      return this.$i18nToastProvider.error(_('activity.retrieve.failed'), { activityId });
    }
  }

  async fetchAll(organizationUnitId: number, statusId?: number, limit = 100, skip = 0, sortField = 'created_at', sortDirection = 'DESC', archived = false, nodeOnly = false) {
    try {
      const result = (await firstValueFrom(this.$baseApi
        .genericListCall('/activity/activity', limit || 100, skip || 0, sortField, sortDirection, {
          archived,
          nodeOnly,
          organizationUnitId,
          statusId,
        }))) as IActivityListResponse;

      if (result) {
        return result;
      }
      return { items: [] };
    } catch (e) {
      throw e;
    }
  }

  async fetchApprovalCount(organizationUnitId: number) {
    try {
      const result = await firstValueFrom(this.$baseApi.genericListCall('/activity/activity/approval/count/', undefined, undefined, undefined, undefined,
        { organization_unit_id: organizationUnitId }));

      return result as ICountResponse;
    } catch (e) {
      return null;
    }

    return null;
  }

  create(organizationUnitId: number, activity: IActivity) {
    return this.$baseApi.genericPostCall('/activity/activity', activity, null, {
      organizationUnitId,
    });
  }

  async update(activityId: number, activity: IActivity) {
    const updatedActivity = (await firstValueFrom(this.$baseApi.genericPutCall(`/activity/activity/${activityId}`, activity))) as IActivity;
    const currentStoreEntity = await this.query.getEntity(updatedActivity.id);

    if (currentStoreEntity && updatedActivity) {
      this.store.update(updatedActivity.id, {
        ...currentStoreEntity,
        data: updatedActivity,
      });
    } else if (updatedActivity) {
      this.store.upsert(updatedActivity.id, {
        id: updatedActivity.id,
        data: updatedActivity,
      });
    }

    return updatedActivity;
  }

  async archive(activityId: number) {
    await firstValueFrom(this.$baseApi.genericDeleteCall(`/activity/activity/${activityId}`));

    this.store.remove(activityId);
  }

  async copy(activityId: number, organizationUnitId: number, optionalParams?: {
    statusId?: number, archived?: boolean, autoTranslate?: boolean,
  }) {
    let url = `/activity/activity/${activityId}/organization-unit/${organizationUnitId}`;

    url = this.$baseApi.appendQueryParam(url, 'statusId', optionalParams.statusId ?? 1);
    url = this.$baseApi.appendQueryParam(url, 'archived', optionalParams.archived ?? false);
    if (optionalParams?.autoTranslate) {
      url = this.$baseApi.appendQueryParam(url, 'autoTranslate', optionalParams.autoTranslate);
    }

    const copiedActivity = (await firstValueFrom(this.$baseApi.genericPutCall(url))) as IActivity;

    return copiedActivity;
  }

  approve(activityId: number, organizationUnitId: number) {
    return this.$baseApi.genericPutCall(`/activity/activity/organization-unit/${organizationUnitId}/approved/${activityId}`);
  }

  unapprove(activityId: number, organizationUnitId: number) {
    return this.$baseApi.genericDeleteCall(`/activity/activity/organization-unit/${organizationUnitId}/approved/${activityId}`);
  }

  media(activityId: number) {
    return this.$baseApi.genericGetCall(`/activity/activity/${activityId}/media`);
  }

  organizationUnits(activityId: number) {
    return this.$baseApi.genericGetCall(`/activity/activity/${activityId}/organization-unit`);
  }

  requestReview(activityId: number, organizationUnitId: number, sourceOrganizationUnitId: number) {
    return this.$baseApi.genericPutCall(`/activity/activity/${activityId}/organization-unit/${organizationUnitId}/review/${sourceOrganizationUnitId}`);
  }

  updateOrganizationUnits(activityId: number, roles: IOrganizationUnitActivityAssignments) {
    return this.$baseApi.genericPostCall(`/activity/activity/${activityId}/organization-unit`, roles);
  }

  activityKind(activityId: number, limit: number = 100, skip: number = 0, sortField: string = 'created_at', sortDirection: string = 'desc', search: string = '') {
    return this.$baseApi.genericListCall(`/activity/activity/${activityId}/activity-kind`, limit, skip, sortField, sortDirection, { search });
  }

  addActivityKind(activityId: number, activityKindId: number, weight?: number) {
    return this.$baseApi.genericPutCall(`/activity/activity/${activityId}/activity-kind/${activityKindId}`, undefined, { weight });
  }

  archiveActivityKind(activityId: number, activityKindId: number) {
    return this.$baseApi.genericDeleteCall(`/activity/activity/${activityId}/activity-kind/${activityKindId}`);
  }

  areaOfDevelopment(activityId: number, limit: number = 100, skip: number = 0, sortField: string = 'created_at', sortDirection: string = 'desc', search: string = '') {
    return this.$baseApi.genericListCall(`/activity/activity/${activityId}/area-of-development`, limit, skip, sortField, sortDirection, { search });
  }

  addAreaOfDevelopment(activityId: number, areaOfDevelopmentId: number, weight?: number) {
    return this.$baseApi.genericPutCall(`/activity/activity/${activityId}/area-of-development/${areaOfDevelopmentId}`, undefined, { weight });
  }

  archiveAreaOfDevelopment(activityId: number, areaOfDevelopmentId: number) {
    return this.$baseApi.genericDeleteCall(`/activity/activity/${activityId}/area-of-development/${areaOfDevelopmentId}`);
  }

  activityRangeOfDevelopment(activityId: number, limit: number = 100, skip: number = 0, sortField: string = 'created_at', sortDirection: string = 'desc', search: string = '') {
    return this.$baseApi.genericListCall(`/activity/activity/${activityId}/range-of-development`, limit, skip, sortField, sortDirection, { search });
  }

  addRangeOfDevelopment(activityId: number, rangeOfDevelopmentId: number) {
    return this.$baseApi.genericPutCall(`/activity/activity/${activityId}/range-of-development/${rangeOfDevelopmentId}`);
  }

  archiveRangeOfDevelopment(activityId: number, rangeOfDevelopmentId: number) {
    return this.$baseApi.genericDeleteCall(`/activity/activity/${activityId}/range-of-development/${rangeOfDevelopmentId}`);
  }

  ageGroup(activityId: number, limit: number = 100, skip: number = 0, sortField: string = 'created_at', sortDirection: string = 'desc', search: string = '') {
    return this.$baseApi.genericListCall(`/activity/activity/${activityId}/age-group`, limit, skip, sortField, sortDirection, { search });
  }

  addAgeGroup(activityId: number, ageGroupId: number, weight?: number) {
    return this.$baseApi.genericPutCall(`/activity/activity/${activityId}/age-group/${ageGroupId}`, undefined, { weight });
  }

  archiveAgeGroup(activityId: number, ageGroupId: number) {
    return this.$baseApi.genericDeleteCall(`/activity/activity/${activityId}/age-group/${ageGroupId}`);
  }

  activityLocation(activityId: number, limit: number = 100, skip: number = 0, sortField: string = 'created_at', sortDirection: string = 'desc', search: string = '') {
    return this.$baseApi.genericListCall(`/activity/activity/${activityId}/location`, limit, skip, sortField, sortDirection, { search });
  }

  addActivityLocation(activityId: number, locationId: number) {
    return this.$baseApi.genericPutCall(`/activity/activity/${activityId}/location/${locationId}`);
  }

  archiveActivityLocation(activityId: number, locationId: number) {
    return this.$baseApi.genericDeleteCall(`/activity/activity/${activityId}/location/${locationId}`);
  }

  activityTag(activityId: number, limit: number = 100, skip: number = 0, sortField: string = 'created_at', sortDirection: string = 'desc', search: string = '') {
    return this.$baseApi.genericListCall(`/activity/activity/${activityId}/tag`, limit, skip, sortField, sortDirection, { search });
  }

  addActivityTag(activityId: number, tagId: number) {
    return this.$baseApi.genericPutCall(`/activity/activity/${activityId}/tag/${tagId}`);
  }

  archiveActivityTag(activityId: number, tagId: number) {
    return this.$baseApi.genericDeleteCall(`/activity/activity/${activityId}/tag/${tagId}`);
  }

  attachment(activityId: number, limit: number = 100, skip: number = 0, sortField: string = 'created_at', sortDirection: string = 'desc') {
    return this.$baseApi.genericListCall(`/media/attachment/${activityId}`, limit, skip, sortField, sortDirection);
  }

  addAttachment(activityId: number, mediaId: number) {
    return this.$baseApi.genericPutCall(`/media/attachment/${activityId}/attachment/${mediaId}`);
  }

  archiveAttachment(activityId: number, mediaId: number) {
    return this.$baseApi.genericDeleteCall(`/media/attachment/${activityId}/attachment/${mediaId}`);
  }

  groupSize(activityId: number, limit: number = 100, skip: number = 0, sortField: string = 'created_at', sortDirection: string = 'desc', search: string = '') {
    return this.$baseApi.genericListCall(`/activity/activity/${activityId}/group-size`, limit, skip, sortField, sortDirection, { search });
  }

  addGroupSize(activityId: number, groupSizeId: number) {
    return this.$baseApi.genericPutCall(`/activity/activity/${activityId}/group-size/${groupSizeId}`);
  }

  archiveGroupSize(activityId: number, groupSizeId: number) {
    return this.$baseApi.genericDeleteCall(`/activity/activity/${activityId}/group-size/${groupSizeId}`);
  }

  activityType(activityId: number, limit: number = 100, skip: number = 0, sortField: string = 'created_at', sortDirection: string = 'desc', search: string = '') {
    return this.$baseApi.genericListCall(`/activity/activity/${activityId}/activity-type`, limit, skip, sortField, sortDirection, { search });
  }

  addActivityType(activityId: number, activityTypeId: number, weight?: number) {
    return this.$baseApi.genericPutCall(`/activity/activity/${activityId}/activity-type/${activityTypeId}`, undefined, {
      weight,
    });
  }

  archiveActivityType(activityId: number, activityTypeId: number) {
    return this.$baseApi.genericDeleteCall(`/activity/activity/${activityId}/activity-type/${activityTypeId}`);
  }

  kvsObservationPoints(activityId: number, limit: number = 100, skip: number = 0, sortField: string = 'code', sortDirection: string = 'desc') {
    return this.$baseApi.genericListCall(`/activity/activity/${activityId}/kvs-observation-point`, limit, skip, sortField, sortDirection);
  }

  addKvsObservationPoint(activityId: number, observationPointCode: string, weight: number) {
    return this.$baseApi.genericPutCall(`/activity/activity/${activityId}/kvs-observation-point/${observationPointCode}`, undefined, {
      weight,
    });
  }

  archiveKvsObservationPoint(activityId: number, observationPointCode: string) {
    return this.$baseApi.genericDeleteCall(`/activity/activity/${activityId}/kvs-observation-point/${observationPointCode}`);
  }

  getCopyAndSource(activityId: number) {
    return this.$baseApi.genericGetCall(`/activity/activity/${activityId}/related`) as Observable<IRelatedActivityResponse>;
  }

  eventLog(activityId: number, limit: number = 20, skip: number = 0) {
    return this.$baseApi.genericGetCall(`/activity/activity/${activityId}/event-log?limit=${limit}&skip=${skip}`) as Observable<IEventLogListResponse>;
  }

  activityExport(organizationUnitId: number, requestParams: IActivityActivityExportBody) {
    let url = `${this.$baseApi.API_END_POINT}/activity/activity/organization/${organizationUnitId}/export`;
    const headers = this.$baseApi.getAuthorizationHeader();

    const fullFileName = requestParams.filename.endsWith('.xlsx') ? requestParams.filename : `${requestParams.filename}.xlsx`;
    requestParams.filename = fullFileName;

    this.$doenkidsFileDownloaderService.addDownload({ name: fullFileName, url, headers, method: 'POST', requestBody: requestParams });
  }

  doenkidsActivityExport() {
    const url = `${this.$baseApi.API_END_POINT}/activity/activity/organization/doenkids-export`;
    const headers = this.$baseApi.getAuthorizationHeader();

    this.$doenkidsFileDownloaderService.addDownload({ name: 'doenkids-activiteit-export.xlsx', url, headers, method: 'GET' });
  }

  activityMediaUsageExport(organizationUnit: IOrganizationUnitOverview) {
    const url = `${this.$baseApi.API_END_POINT}/activity/activity/${organizationUnit.id}/media-usage/export`;
    const headers = this.$baseApi.getAuthorizationHeader();

    const ouName = organizationUnit.name ?? organizationUnit.id?.toString() ?? '';

    this.$doenkidsFileDownloaderService.addDownload({ name: `${ouName.toLowerCase()}-activiteiten-met-media-gebruik-export.xlsx`, url, headers, method: 'GET' });
  }

  relatedActivities(activityId: number, limit: number = 100, skip: number = 0, sortField: string = 'created_at', sortDirection: string = 'desc') {
    return this.$baseApi.genericListCall(`/activity/related-activity/${activityId}`, limit, skip, sortField, sortDirection) as Observable<IActivityListResponse>;
  }

  addRelatedActivity(fromActivityId: number, toActivityId: number) {
    return this.$baseApi.genericPutCall(`/activity/related-activity/${fromActivityId}/activity/${toActivityId}`);
  }

  removeRelatedActivity(fromActivityId: number, toActivityId: number) {
    return this.$baseApi.genericDeleteCall(`/activity/related-activity/${fromActivityId}/activity/${toActivityId}`);
  }

  listConnections(activityId: number, organizationUnitId?: number) {
    let url = `/activity/activity/list-connections/${activityId}`;
    url = this.$baseApi.appendQueryParam(url, 'organization_unit_id', organizationUnitId);

    return this.$baseApi.genericPostCall(url, {}) as Observable<IActivityConnectionList[]>;
  }
}

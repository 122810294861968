export const environment = {
  production: true,
  enabledLanguages: ['en-GB'],
  testEnabledLanguages: ['en-GB'],
  defaultLanguage: 'en-GB',
  angularLanguageLocaleMap: { 'en-GB': 'en-GB' },
  activityImagePlaceholder: './assets/images/activities/activity-placeholder-beinspired.png',
  avatarPlaceholder: './assets/images/avatar-beinspired.png',
  activity404Image: './assets/images/activities/activity-404-beinspired.png',
  activityBlockedImage: './assets/images/activities/activity-blocked.jpg',
  poweredByLogo: './assets/images/logo-be-inspired.png',
  showLogoAboveNavigation: false,
  auth0Tennant: {
    baseUrl: 'https://cdn.eu.auth0.com',
    clientId: 'RLVu2fgOKDk0Zra80yS1DV5PDleoB7n5',
    domain: 'login.doenkids.nl',
    audience: 'https://api.doenkids.nl',
    title: 'BE Inspired',
    logoUrl: './assets/images/logo-be-inspired.png',
    themeColor: '#00B098',
  },
};
